/* Căn chỉnh khung dropdown */
.navbar .dropdown-menu {
    left: -100px; 
    top: 50px; 
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(10px);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.navbar .dropdown-menu.show {
    opacity: 1;
    transform: translateY(0);
}

.navbar .dropdown-menu::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 20px; 
    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent transparent #ff69b4 transparent;
}

.navbar .dropdown-item {
    padding: 8px 15px;
    border-radius: 4px;
}

.navbar .dropdown-item:hover {
    background-color: #fd83c0;
}
